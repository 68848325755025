<template>
  <b-modal id="post-simulacion-download" ref="downloadMoal" centered no-close-on-backdrop title="Download"
    ok-title="Descargar" @ok="downloadFile" @show="cleanSelection" cancel-variant="outline-secondary">
    <template #modal-ok>
      <div v-if="isLoadingButton">
        <b-spinner small />
        Descargando...
      </div>
      <div v-else>
        Descargar
      </div>
    </template>

    <b-card-text>
      <span class="d-flex mb-1"><strong>Confirme la data que desea descargar: </strong></span>
      <v-select v-model="selectedMonth" :options="optionsSelect" :clearable="false" :reduce="val => val.value"
        placeholder="Seleccione el id">
        <template v-slot:no-options>
          No hay datos
        </template>

        <template v-slot:option="option">
          <b-img :src="require('@/assets/images/icons/xls.png')" width="20px" alt="excel file image" class="mr-1" />
          {{ option.label }}
        </template>
      </v-select>
    </b-card-text>
  </b-modal>
</template>

<script>
import { BButton, BModal, BCardText, BSpinner, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, getCurrentInstance } from 'vue'
import axios from '@axios'
import vSelect from 'vue-select'
import gql from 'graphql-tag'

export default {
  components: {
    BButton,
    BModal,
    BCardText,
    BSpinner,
    BImg,

    vSelect,
  },
  directives: {
    Ripple,
  },
  setup(props) {
    // props
    const selectedMonth = ref(0)
    const downloadMoal = ref(null)
    const isLoadingButton = ref(false)
    const optionsSelect = ref([])

    const vm = getCurrentInstance().proxy

    const downloadFile = async (bvModalEvt) => {
      bvModalEvt.preventDefault()
      const btnModal = document.getElementById('post-simulacion-download___BV_modal_footer_').children[1]
      try {
        if (selectedMonth.value.length === 0) {
          return
        }

        isLoadingButton.value = true

        btnModal.setAttribute('disabled', true)

        const response = await axios
          .post(`${process.env.VUE_APP_BASE_URL}/get_bd_postsimulador`,
            {
              data: {
                id: selectedMonth.value
              }
            },
            {
              responseType: 'blob'
            }
          )

        const filename = response.headers.filename;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        vm.$swal({
          title: 'Error!',
          text: `No se pudo descargar los datos del database `,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } finally {
        btnModal.removeAttribute('disabled')
        isLoadingButton.value = false
      }
    }

    // subscription
    vm.$apollo.addSmartSubscription('getIdVisualDatabase', {
      query: gql`
        subscription {
          view_db_main_last_id(distinct_on: id) {
            label: id,
            value: id,
          }
        }
      `,
      result({ data }) {
        formatDate(data.view_db_main_last_id)
        optionsSelect.value = data.view_db_main_last_id
      },
    })

    const cleanSelection = () => {
      selectedMonth.value = []
    }

    const formatDate = (arrayDate) => {
      for (let item of arrayDate) {
        item.label = item.label.slice(0, 4) + '-' + item.label.slice(4,)
      }
    }

    return {
      downloadMoal,
      isLoadingButton,
      selectedMonth,
      optionsSelect,

      downloadFile,
      cleanSelection,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>