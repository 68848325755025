<template>
  <b-card no-body>
   
    <b-card-body>

      <post-simulacion-download-modal></post-simulacion-download-modal>

        <b-row class="align-items-center">

          <tabla-post-simulacion-filters :filtersData="filter" :is-filters-post-simulador-active.sync="isFiltersPostSimuladorActive"
          @filters-post-simulador-data="data=> setFiltersData(data)" />

          <div v-if="filter.bpu && filter.bpu.length > 0" class="mr-1"><strong>BPU: </strong> {{ filter.bpu[0] }}</div>
          <div v-if="filter.brand_category && filter.brand_category.length > 0" class="mr-1"><strong>BRAND CATEGORY:
            </strong>
            {{ filter.brand_category[0] }}</div>
          <div v-if="filter.application_form && filter.application_form.length > 0" class="mr-1"><strong>APPLICATION FORM:
            </strong>
            {{ filtersDatasData.application_form[0] }}</div>
          <div v-if="filter.canal && filter.canal.length > 0" class="mr-1"><strong>CANAL: </strong> {{
            filter.canal[0] }}</div>
          <div v-if="filter.clasificacion && filter.clasificacion.length > 0" class="mr-1"><strong>TYPE: </strong> {{
            filter.clasificacion[0] }}</div>
          <div v-if="filter.year && filter.year.length > 0" class="mr-1"><strong>YEAR: </strong>
            {{ filter.year[0] }}</div>
          <div v-if="filter.month && filter.month.length > 0" class="mr-1"><strong>MONTH: </strong> {{
            filter.month[0] }}</div>

        </b-row>

        <b-row class="mb-1">
          <b-button  @click="isFiltersPostSimuladorActive = true" variant="primary" class="ml-1">
          <b-icon-sliders/>  Filter Data
          </b-button>

          <b-button v-b-modal.post-simulacion-download variant="primary" class="ml-1">
          <b-icon-download/>  Download
          </b-button>

          <!-- agregando Cerrar SOP -->
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="cerrarDataSop" class="ml-1">
          <b-icon-caret-right-square/>  Cerrar S&OP
          </b-button>
          <!--  -->


        </b-row>
        <b-row style="padding: 13px">

        <div v-if="isLoading" class="my-3">
          <custom-loading></custom-loading>
        </div>
          <v-grid ref="refGrid" :source="source" :columns="columns" theme="material" resize="true" autoSizeColumn=true
            range="true" :columnTypes="pluginRevo" class="grid-container-tabla-post-simulacion">
          </v-grid>
        </b-row>
      </b-card-body>
    
  </b-card>
</template>
  
<script>
import {
  BCard, BButton, BCardBody,
  BRow, BIconSliders, BIconDownload, BIconCaretRightSquare
} from 'bootstrap-vue'
import { ref, getCurrentInstance, toRefs, watch } from 'vue'
import VGrid from '@revolist/vue-datagrid';
import axios from '@axios'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import TablaPostSimulacionFilters from './TablaPostSimulacionFilters.vue'
import PostSimulacionDownloadModal from './PostSimulacionDownloadModal.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'
import { numericSort } from '@/libs/revo-grid/custom-sort'


export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,
    TablaPostSimulacionFilters,
    CustomLoading,
    PostSimulacionDownloadModal,
    VGrid,
    BIconSliders,BIconDownload,BIconCaretRightSquare
  },
  data() {
    return {
      isFiltersPostSimuladorActive: false,

    }
  },
   methods: {
    setFiltersData(data) {

      const setFilter = Object.assign({}, data);
      this.filter = setFilter;
    }
  },
  setup() {
    const filter = ref({});
    const source = ref([])
    const isLoading = ref(true)
    const refGrid = ref(null)
    const columns = ref([])



    const pluginRevo = { 'numeric': new NumberColumnType('0,0') }

    const vm = getCurrentInstance().proxy


    watch(filter, (newValue, oldValue) => {
      isLoading.value = true;

      // console.log('cambio', Date.now())
    }, { deep: true })

    // Boton cerrar sop
    const cerrarDataSop = () => {
      vm.$swal({
        title: `¿Está seguro de ejecutar el proceso Cerrar Proceso SOP?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        backdrop: false,
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios
            .post(`${process.env.VUE_APP_BASE_URL}/cerrar_db_main`)
            .then(response => {
              if (response.status != 200) {
                throw new Error(response.statusText)
              }
              return response
            })
            .catch(error => {
              vm.$swal.showValidationMessage(`Request failed:  ${error}`)
            })
        },
      }).then(async (result) => {
        if (result.value) {
          vm.$swal({
            icon: 'success',
            title: 'Proceso Cerrar Proceso SOP Ejecutado!',
            customClass: {
              confirmButton: 'btn btn-success',
            }
          })
        }
      })
    }
    //

    // subscription
    columns.value = [
      {
        "name": "ID",
        "prop": "id",
        "size": 80,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "BPU",
        "prop": "bpu",
        "size": 110,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "BRAND CATEGORY",
        "prop": "brand_category",
        "size": 200,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "APPLICATION FORM",
        "prop": "application_form",
        "size": 200,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "CANAL",
        "prop": "canal",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "TYPE",
        "prop": "clasificacion",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "YEAR",
        "prop": "year",
        "size": 85,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "MONTH",
        "prop": "month",
        "size": 85,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "QUARTER",
        "prop": "quarter",
        "size": 92,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "NEW NS",
        "prop": "ns_new",
        "size": 100,
        "autoSize": true,
        "sortable": true,
        "readonly": false,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "OLD NS",
        "prop": "netsales",
        "size": 100,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "CONTRAST",
        "prop": "diferencia",
        "size": 100,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "MODIFIED?",
        "prop": "modificado",
        "size": 130,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      }
    ]

    vm.$apollo.addSmartSubscription('getVisualDatabase', {
      query: gql`
      subscription get_DemandSimulation($customWhere: json = null) {
        function_get_post_simulation(args: {customWhere: $customWhere},order_by: { month: desc, year: desc, clasificacion:asc}) {                
              id
              bpu
              brand_category
              application_form
              canal
              clasificacion
              year
              month
              quarter
              ns_new
              netsales
              diferencia
              modificado

            }
          }
        `,
        variables() {
        return {
          customWhere: filter.value
        }
      },
      result({ data }) {
        isLoading.value = false
        source.value = data.function_get_post_simulation
      },
    })





    return {
      filter,
      source,
      columns,
      isLoading,
      refGrid,
      pluginRevo,
      cerrarDataSop

    }
  },
}
</script>
  
<style lang="scss">
.grid-container-tabla-post-simulacion {
  width: 100%;
  height: 80vh;
  font-size: 12px;
  font-family: "Montserrat", Helvetica, Arial, serif !important;
 

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #00a05d;
    //background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
  